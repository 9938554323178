import React, { Component } from 'react'
import { connect } from 'react-redux'

import { notify } from 'Actions/notifications'
import Dropzone from 'Ui/Dropzone'
import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'

import 'Ui/input.sass'
import 'Ui/select.sass'
import '../../SubscriptionPage/steps/step.sass'
import './aml-modal.sass'
import { endsWith, keys, omit } from 'lodash'


class AmlModalStep3 extends Component {
  constructor(props) {
    super(props)

    const initialState = {
      fileSuspended: false,
      isSubmitting: false,
      beneficiaires: {},
    }
    
    props.currentUser.beneficiairesNeedingJustif.forEach(b => {
      initialState.beneficiaires[b.id] = {
        type: 'ID_CARD',
        file1: { file: null, error: null },
        file2: { file: null, error: null },
      }
    })

    this.state = initialState
  }

  setFileState(benefId, data) {
    this.setState({
      beneficiaires: {
        ...this.state.beneficiaires,
        [benefId]: {
          ...this.state.beneficiaires[benefId],
          ...data,
        },
      },
    })
  }

  validateFileForm(benefId) {
    const benefState = this.state.beneficiaires[benefId]
    
    if (!benefState.file1.file) return false
    if (benefState.type !== 'PASSPORT' && !benefState.file2.file) return false

    return true
  }
  
  handleSubmitFile(benefId) {
    if (this.state.isSubmitting) return

    const { refreshUser, dispatch } = this.props
    const benefFile = this.state.beneficiaires[benefId]
    if (!this.validateFileForm(benefId)) {
      this.setFileState(benefId, { error: 'Veuillez sélectionner un fichier avant de valider' })
      return
    }
    
    const formData = new FormData()
    formData.append('type', benefFile.type)
    formData.append('file1', benefFile.file1.file)
    
    if (benefFile.file2.file) {
      formData.append('file2', benefFile.file2.file)
    }

    this.setState({ isSubmitting: true })
    
    fetch(`/api/justificatifs/${benefId}/beneficiaire_id_justif_file`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    })
      .then(res => {
        if (res.status !== 200) throw new Error(res.error)
        return res.json()
      })
      .then(res => {
        if (res.result === 'file_suspended') {
          // means waiting for manual validation, so we refresh page and this benef will be removed from the list,
          // or the user will get redirected to "en attente de validation" page if there are no other benef needing justif
          window.location.reload()
          return
        }

        refreshUser(res => {
          this.setState({ isSubmitting: false })
          const updatedBenefs = {}

          res.beneficiairesNeedingJustif.forEach(b => {
            updatedBenefs[b.id] = {
              type: 'ID_CARD',
              file1: { file: null, error: null },
              file2: { file: null, error: null },
            }
          })

          this.setState({
            ...this.state,
            beneficiaires: updatedBenefs,
          })
        })

        if (res.result === 'success' || res.result === 'already_validated') {
          dispatch(notify('success', res.result === 'already_validated'
            ? 'Ce justificatif avait déjà été validé.'
            : 'Justificatif enregistré'
          ))
        } else if (res.result === 'validation_failure') {
          dispatch(notify('error', 'Le document est invalide ou n’a pas pu être analysé, merci de vérifier et de réessayer avec un autre document. En cas de problème récurrent, merci d’adresser le document par email à contact@ma-domiciliation.fr', 10000))
        }
      })
      .catch((e) => {
        console.log('e ------------------------------------------>')
        console.log(e)
        this.setState({ isSubmitting: false })
        dispatch(notify('error', 'Une erreur est survenue.'))
      })
  }

  handleSelectType(value, benefId) {
    this.setFileState(benefId, {
      type: value,
    })
  }

  renderBeneficiaireForm(benef) {
    return (
      <div key={benef.id}>
        <div className="mb-5">
          <div className="text-lg uppercase mb-5">
            {JSON.parse(benef.firstnames).join(' ')} {benef.name}
          </div>
          <div className="flex justify-center align-center mb-3">
            <div className="mr-2">Type de document :</div>
            <select className="Input-group select border-black border-1 rounded" value={this.state.beneficiaires[benef.id].type} onChange={e => this.handleSelectType(e.target.value, benef.id)}>
              <option value="ID_CARD">Carte d'identité</option>
              <option value="PASSPORT">Passeport</option>
              <option value="RESIDENCE_PERMIT">Titre de séjour</option>
            </select>
          </div>
          {this.renderBeneficiaireFileDropzones(benef)}
        </div>
      </div>
    )
  }
  
  renderBeneficiaireFileDropzones(benef) {
    const benefState = this.state.beneficiaires[benef.id]

    let dropZones = benefState.type === 'PASSPORT'
      ? this.renderDropzone(benef, 1)
      : (
          <>
            <div className="">Recto :</div>
            {this.renderDropzone(benef, 1)}
            <div className="">Verso :</div>
            {this.renderDropzone(benef, 2)}
          </>
      )

    return (
      <>
        {dropZones}
        {benefState.error && (
          <div className="error mt-2">
            <p>{benefState.error}</p>
          </div>
        )}
        <div className="right-container mt-4 mb-8">
          <SubmitSpinnerButton isSubmitting={this.state.isSubmitting}>
            <button className="btn valid small-height" onClick={() => this.handleSubmitFile(benef.id)}>
              Valider
            </button>
          </SubmitSpinnerButton>
        </div>
      </>
    )
  }

  renderDropzone(benef, number) {
    const benefState = this.state.beneficiaires[benef.id] || {}
    const fieldName = number === 1 ? 'file1' : 'file2'
    const stateFile = benefState[fieldName]

    const onDrop = files => {
      if (files.length > 1) {
        this.setFileState(benef.id, { [fieldName]: { error: 'Veuillez sélectionner un seul fichier par champ' } })

        return
      }

      if (files[0].size >= 20971520) {
        this.setFileState(benef.id, { [fieldName]: { error: 'Ce fichier est trop volumineux (20Mo maximum).' } })

        return
      }
      
      this.setFileState(benef.id, {
        [fieldName]: { file: files[0] },
        error: null,
      })
    }

    if (!stateFile.file) {
      return (
        <>
          <Dropzone onDrop={onDrop} />
          {stateFile.error && (
            <div className="error">
              <p>{stateFile.error}</p>
            </div>
          )}
        </>
      )
    }

    return (
      <div className="selected-file">
        <img
          width="20"
          height="20"
          className="checkmarck-icon"
          src="/assets/icons/ic-checkmark-green.svg"
          alt="Validé"
        />
        <span>Fichier sélectionné : {stateFile.file.name}</span>
      </div>
    )
  }
  
  renderBeneficiairesFileQuestion() {
    const { currentUser } = this.props
    const beneficiairesNeedingFile = currentUser.beneficiairesNeedingJustif.filter(benef => {
      return !!this.state.beneficiaires[benef.id]
    })

    if (!beneficiairesNeedingFile.length) return null
    
    return (
      <div className="form-block">
        <div className="label-form">
          <span className="step-subtitle">Justificatifs d’identité des bénéficiaires effectifs</span>
        </div>
        <div className="ppe-text mb-5">
          Pour des raisons réglementaires, merci de fournir un justificatif d’identité (passeport, carte d’identité ou titre de séjour) pour tous les bénéficiaires effectifs listés ci-dessous :
        </div>
        {beneficiairesNeedingFile.map(benef => this.renderBeneficiaireForm(benef))}
      </div>
    )
  }

  render() {
    console.log('this.state --------------------------------->')
    console.log(this.state)
    return (
      <>
        <h3>Mise à jour réglementaire de votre fiche client</h3>
        {this.renderBeneficiairesFileQuestion()}
      </>
    )
  }
}


const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(AmlModalStep3)
