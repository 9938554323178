import React, { useState, useCallback } from 'react'
import axios from 'axios'
import { connect, useDispatch } from 'react-redux'
import sirenValidator from '../../../helpers/siren-validator'
import { notify } from 'Actions/notifications'

import 'Ui/input.sass'
import '../../SubscriptionPage/steps/step.sass'
import '../AdressPage/editAddressModal.sass'

const WrongSirenModal = ({ currentUser, close }) => {
  const dispatch = useDispatch()
  const [sirenField, setSirenField] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isStillIncorrectSiren, setIsStillIncorrectSiren] = useState(false)
  const [error, setError] = useState()

  const updateSiren = useCallback(async (siren) => {
    setError()
    setIsLoading(true)
    try {
      const { data: user } = await axios.put(`/api/user/${currentUser.id}/siren`, { siren, isWrongSirenModal: true })

      if (user.result === 'BLOCKED_DUPLICATE') {
        // just refresh the page, blocking is handled when displaying CustomerArea
        window.location.reload()
        return
      }

      if (user.inpiData?.social_denomination_score && user.inpiData.social_denomination_score >= .7) {
        dispatch(notify('info', 'SIREN validé.', 4000))
        // refresh page after siren update, because we need to check if kbis and status modals are now required for this user
        window.location.reload()
      } else {
        setIsStillIncorrectSiren(true)
      }
    } catch (err) {
      if (err.response && err.response.data === 'IS_COMPANY_SIREN') {
        setError('Merci de renseigner le SIREN de votre société, et non pas le nôtre')
      } else {
        setError('Une erreur est survenue')
      }
    }
    setIsLoading(false)
  }, [currentUser, dispatch])

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()

    setError()

    if (sirenField.includes(' ')) {
      return setError('Merci d’enlever les espaces.')
    }

    if (!sirenValidator(sirenField)) {
      return setError("Le SIREN saisi n'est pas au bon format. Un SIREN est composé de 9 chiffres")
    }

    updateSiren(sirenField)
  }, [sirenField, setError])

  if (isStillIncorrectSiren) {
    return (
      <div className="AmlModal">
        <div className="form-block">
          <span className="step-subtitle">SIREN invalide</span>
          <div className="ppe-text mb-5">
            Merci de nous écrire à contact@ma-domiciliation.fr avec comme objet “SIREN invalide”, pour que nous puissions débloquer votre compte.
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="AmlModal">
      <form className="form-block" onSubmit={onSubmit}>
        <div className="label-form">
          <span className="step-subtitle">Votre Siren semble erroné</span>
        </div>
        <div className="ppe-text mb-5">
          Si vous pensez qu’il s’agit d’une erreur, merci de nous adresser un email à contact@ma-domiciliation.fr
        </div>
        <div className="ppe-text mb-5">
          Voici les informations correspondant au siren que vous avez fourni :
        </div>
        <div className="ppe-text">
          Siren: <span className="bold">{currentUser.siren}</span>
        </div>
        {currentUser.inpiData ? (
          <div className="ppe-text mb-5">
            Dénomination sociale:{' '}
            <span className="bold">{currentUser.inpiData.social_denomination}</span>
          </div>
        ) : null}
        {currentUser.forme_juridique_pappers_matches === false ? (
          <div className="ppe-text">
            Forme juridique:{' '}
            <span className="bold">{currentUser.forme_juridique_pappers}</span>
          </div>
        ) : null}

        <div className="ppe-text">J'entre un nouveau Siren :</div>

        <input
          type="text"
          placeholder="SIREN de votre société"
          className="Input-group"
          value={sirenField}
          onChange={e => setSirenField(e.target.value)}
          style={{ marginBottom: 4 }}
        />

        {!!error && (
          <p className="ppe-error">
            {error}
          </p>
        )}

        <div className="center-container mt-8">
          <button className="btn valid small-height" type="submit" disabled={isLoading}>
            {isLoading ? 'Envoi en cours...' : 'Valider'}
          </button>
        </div>
      </form>
    </div>
  )
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(WrongSirenModal)
