import Button from '@components/Button'
import './representant-confirmation-modal.sass'

interface Props {
  variant: 'subscription' | 'actualisation'
  name: string
  address: string
  tel: string
  onConfirm: () => void
  onClose: () => void
  close: () => void
}

const RepresentantConfirmationModal = ({
  variant,
  name,
  address,
  tel,
  onConfirm,
  onClose,
  close,
}: Props) => {
  return (
    <div className="RepresentantConfirmationModal">
      <div className="text-2xl text-center uppercase pt-3 pb-7 bold">Validation</div>
      <p className="mb-2">
        Attention, merci de bien vérifier les informations suivantes, car nous allons vous demander à l’étape suivante :
      </p>
      <ul className="mb-10">
        <li className="ml-4">• un justificatif de domicile personnel de moins de 3 mois au nom de {name} à l’adresse suivante : {address}</li>
        <li className="ml-4">• de signer le contrat avec un envoi de code par SMS au {tel}</li>
      </ul>
      <div className="flex gap-12 mt-4 mb-[48px] md:w-[60%] mx-auto">
        <Button
          onClick={close}
        >
          MODIFIER
        </Button>
        <Button onClick={() => {
          onConfirm()
          close()
        }}>
          VALIDER
        </Button>
      </div>
      <div className="text-xl mb-6 text-center">Questions fréquentes :</div>
      <div className="faq-list">
        <div className="faq-question">
          Quels sont les justificatifs de domicile acceptés ?
        </div>
        <div className="faq-answer">
          Nous acceptons les avis d’imposition, factures EDF (ou autre fournisseur d’énergie), et attestations d’assurance habitation de moins de 3 mois et à votre nom. Nous n’acceptons pas les factures de téléphonie mobile.
        </div>
        <div className="faq-question">
          Je suis hébergé, comment faire ?
        </div>
        <div className="faq-answer">
          Merci de fournir, sur un seul document :
          <ul>
            <li className="ml-4">• l'attestation d'hébergement signée par votre hébergeur (modèle gratuit : <a className="link" href="https://www.service-public.fr/particuliers/vosdroits/R39697" target="_blank" rel="noopener noreferrer">https://www.service-public.fr/particuliers/vosdroits/R39697</a>)</li>
            <li className="ml-4">• le justificatif d'identité de votre hébergeur</li>
            <li className="ml-4">• le justificatif de domicile de votre hébergeur (moins de 3 mois)</li>
          </ul>
          Vous pouvez fusionner ces 3 documents en un seul PDF via ce site <a className="link" href="https://www.ilovepdf.com/fr" target="_blank" rel="noopener noreferrer">https://www.ilovepdf.com/fr</a>
        </div>
        <div className="faq-question">
          Je n’ai pas de justificatif de domicile récent à mon adresse
        </div>
        <div className="faq-answer">
          Si vous venez d’emménager à une nouvelle adresse, alors merci de déclarer votre ancienne adresse, et de joindre votre avis d’imposition ou bien un autre justificatif de moins de 3 mois à cette adresse.
        </div>
        {variant === 'subscription'
          ? (
            <>
              <div className="faq-question">
                Nous sommes plusieurs gérants / présidents
              </div>
              <div className="faq-answer">
                Merci de désigner une seule personne, qui sera signataire du contrat de domiciliation. Cette personne doit être présente sur le Kbis (ou sur le futur Kbis si la société est en cours de création).
              </div>
            </>
          ) : (
            <>
              <div className="faq-question">
              Le gérant / président a changé :
              </div>
              <div className="faq-answer">
                Le nouveau gérant doit se réinscrire sur le site afin de signer le contrat et transmettre ses documents d'identités : <a className="link" href="https://ma-domiciliation.fr/inscription/email" target="_blank" rel="noopener noreferrer">https://ma-domiciliation.fr/inscription/email</a><br/>
                Lors de l'inscription vous devrez cliquer sur "oui, je souhaite transférer le siège" puis renseigner le SIREN.<br/>
                Une fois la nouvelle inscription finalisée, merci de nous adresser un email (<a className="link" href="mailto:contact@ma-domiciliation.fr">contact@ma-domiciliation.fr</a>) afin que l’on vous désabonne l'ancien compte et vous rembourse si un prélèvement est passé entre temps.
              </div>
            </>
          )
        }

        <div className="faq-question">
          Mes justificatifs d’identité et/ou de domicile ne sont pas français
        </div>
        <div className="faq-answer">
          Merci de nous faire parvenir votre carte d’identité ou passeport, traduits en français et avec la mention « certifié conforme », la date du jour ainsi que votre signature.
        </div>
        <div className="faq-question">
          Mes justificatifs ne sont pas acceptés
        </div>
        <div className="faq-answer">
          Nous utilisons un logiciel qui analyse automatiquement vos justificatifs. Il peut arriver que ce logiciel ne reconnaisse pas un justificatif. Merci d’adresser par email à <a className="link" href="mailto:contact@ma-domiciliation.fr">contact@ma-domiciliation.fr</a> votre justificatif d’identité et de domicile, afin que nous l’ajoutions nous-même à votre dossier.
        </div>
      </div>
    </div>
  )
}

export default RepresentantConfirmationModal